import styled from 'styled-components'
import media from "styled-media-query"
import { ArrowDown as Down } from 'styled-icons/evil/ArrowDown'
import { UpArrowAlt as Up } from "styled-icons/boxicons-regular/UpArrowAlt"

export const Arrow = styled(Down).attrs(({ direction, size }) => ({
  as: direction === `up` && Up,
  size,
}))`
  color: var(--texts);
  cursor: pointer;
  height: 3.2rem;
  padding: .9rem;
  position: relative;
  width: 3.2rem;
  &:hover {
    color: var(--baseHighlight);
  }
  ${({ show }) => `
  opacity: ${show ? 1 : 0};
  visibility: ${show ? `visible` : `hidden`};
  :hover {
    transform: scale(1.15);
  }`
  }
  ${media.greaterThan("large")`
    position: fixed;
    right: 1.5em;
    bottom: 8em;
  `}`