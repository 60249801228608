import styled from "styled-components"
import media from "styled-media-query"

export const SidebarWrapper = styled.header`
  align-items: center;
  border-right: 1px solid var(--borders);
  border-left: 1px solid var(--borders);
  background: var(--baseColor);
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  padding: 2rem;
  text-align: center;
  width: 20rem;
  left: 3.75rem;

  ${media.lessThan("large")`
    align-items: flex-start;
    height: auto;
    padding: 1rem 2rem;
    width: 100%;
    left: 0;
  `}
`
