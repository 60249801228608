const handle = "produttivoapp"

const links = [
  {
    label: "Instagram",
    url: `https://www.instagram.com/${handle}`,
  },
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/company/produttivo/`,
  },
  {
    label: "Facebook",
    url: `https://facebook.com/${handle}`,
  },
  {
    label: "Twitter",
    url: `https://twitter.com/${handle}`,
  },
  {
    label: "Youtube",
    url: `https://www.youtube.com/channel/UCxVtC_RdhmyYMo_hPOSy1lA`,
  },
]

export default links
