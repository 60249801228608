import { Instagram } from "styled-icons/boxicons-logos/Instagram"
import { Linkedin } from "styled-icons/boxicons-logos/Linkedin"
import { Facebook } from "styled-icons/boxicons-logos/Facebook"
import { Twitter } from "styled-icons/boxicons-logos/Twitter"
import { Youtube } from "styled-icons/boxicons-logos/Youtube"

const Icons = {
  Instagram,
  Linkedin,
  Facebook,
  Twitter,
  Youtube,
}

export default Icons
